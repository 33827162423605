import { is_enabled_module, is_enabled_setting } from '@/Composable/usePermission'

export default {
    install: (app) => {

        app.config.globalProperties.is_enabled_module = is_enabled_module;
        app.config.globalProperties.is_enabled_setting = is_enabled_setting;



        app.mixin({
            methods: {
                is_enabled_module: is_enabled_module,
                is_enabled_setting: is_enabled_setting,


            },
        });

        app.provide('is_enabled_module', is_enabled_module)
        app.provide('is_enabled_setting', is_enabled_setting)

    }
}
