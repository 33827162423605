import { usePage } from '@inertiajs/vue3'

export default {
    install: (app) => {
        const r = (key, replacements = {}) => {
            let translation = ""


            try {
                translation = usePage().props.translations[key] || key;
            } catch (error) {

                translation = key;
            }



            Object.keys(replacements).forEach(r => {
                translation = translation.replace(`:${r}`, replacements[r]);
            });
            return translation;

        }
        app.config.globalProperties.__t = r;


        app.mixin({
            methods: {
                __t: r,

            },
        });

        app.provide('__t', r)
    }
}
